<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row flex-grow-0 flex-shrink-0" v-if="serverData">
          <!--          <div class="col-12 col-md-6 col-md-4 h-75px" v-if="!isClient">-->
          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.title"
              label="Title"
              clearable
              outlined
              dense
              :error-messages="titleErrors"
              @input="$v.formData.title.$touch()"
              @blur="$v.formData.title.$touch()"
            />
          </div>
          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.receiver_type_id"
              label="Receiver type"
              item-text="text"
              item-value="index"
              :items="receivers"
              clearable
              outlined
              dense
              :error-messages="receiver_type_idErrors"
              @input="$v.formData.receiver_type_id.$touch()"
              @blur="$v.formData.receiver_type_id.$touch()"
            />
          </div>
          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.receiver_id"
              :label="userDetails.label"
              :items="userDetails.list"
              item-text="text"
              item-value="index"
              :multiple="userDetails.isMultiple"
              :small-chips="userDetails.isMultiple"
              :deletable-chips="userDetails.isMultiple"
              clearable
              outlined
              dense
              :error-messages="receiver_idErrors"
              @input="$v.formData.receiver_id.$touch()"
              @blur="$v.formData.receiver_id.$touch()"
            />
          </div>
          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.action_type_id"
              label="Action type"
              :items="serverData.actions"
              item-text="name"
              item-value="id"
              clearable
              outlined
              dense
              :error-messages="action_type_idErrors"
              @input="$v.formData.action_type_id.$touch()"
              @blur="$v.formData.action_type_id.$touch()"
            />
          </div>
          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.action_id"
              :label="actionNotificationDetails.label"
              :items="actionNotificationDetails.list"
              item-value="id"
              item-text="label"
              :multiple="actionNotificationDetails.isMultiple"
              :small-chips="actionNotificationDetails.isMultiple"
              :deletable-chips="actionNotificationDetails.isMultiple"
              clearable
              outlined
              dense
              :error-messages="action_idErrors"
              @input="$v.formData.action_id.$touch()"
              @blur="$v.formData.action_id.$touch()"
            />
          </div>

          <!--              :class="{ 'col-md-6': isClient }"-->
          <template v-if="formData.action_type_id === 1">
            <div class="col-12 col-md-6 h-75px">
              <v-autocomplete
                v-model="formData.day_id"
                label="Day"
                item-text="text"
                item-value="index"
                :items="serverData.days"
                multiple
                small-chips
                deletable-chips
                clearable
                outlined
                dense
                :error-messages="day_idErrors"
                @input="$v.formData.day_id.$touch()"
                @blur="$v.formData.day_id.$touch()"
              />
            </div>

            <div class="col-12 col-md-6 h-75px">
              <v-text-field
                v-model="formData.time"
                label="Time"
                clearable
                outlined
                dense
                :error-messages="timeErrors"
                @input="$v.formData.time.$touch()"
                @blur="$v.formData.time.$touch()"
              />
            </div>
            <!--            <v-dialog-->
            <!--              ref="timeModal"-->
            <!--              v-model="timeModal"-->
            <!--              :return-value.sync="formData.time"-->
            <!--              persistent-->
            <!--              width="490px"-->
            <!--            >-->
            <!--              <template v-slot:activator="{ on, attrs }">-->
            <!--                <div class="col-12 col-md-6 h-75px">-->
            <!--                  <v-text-field-->
            <!--                    v-model="formData.time"-->
            <!--                    label="Time"-->
            <!--                    clearable-->
            <!--                    outlined-->
            <!--                    dense-->
            <!--                    :error-messages="timeErrors"-->
            <!--                    @input="$v.formData.time.$touch()"-->
            <!--                    @blur="$v.formData.time.$touch()"-->
            <!--                    readonly-->
            <!--                    v-bind="attrs"-->
            <!--                    v-on="on"-->
            <!--                  />-->
            <!--                </div>-->
            <!--              </template>-->
            <!--              <v-time-picker-->
            <!--                v-if="timeModal"-->
            <!--                v-model="formData.time"-->
            <!--                full-width-->
            <!--                format="24hr"-->
            <!--                :ampm-in-title="false"-->
            <!--              >-->
            <!--                <v-spacer></v-spacer>-->
            <!--                <button-->
            <!--                  class="btn btn-light mr-3 px-5 py-3 ls1"-->
            <!--                  @click="timeModal = false"-->
            <!--                >-->
            <!--                  Cancel-->
            <!--                </button>-->
            <!--                <button-->
            <!--                  class="btn btn-info px-5 py-3 ls1"-->
            <!--                  @click="$refs.timeModal.save(formData.time)"-->
            <!--                >-->
            <!--                  OK-->
            <!--                </button>-->
            <!--              </v-time-picker>-->
            <!--            </v-dialog>-->
          </template>

          <template
            v-if="
              formData.action_type_id === 2 || formData.action_type_id === 3
            "
          >
            <div class="col-12 h-75px">
              <v-autocomplete
                v-model="formData.template_id"
                label="Template"
                item-text="text"
                item-value="index"
                :items="serverData.templates"
                clearable
                outlined
                dense
                :error-messages="template_idErrors"
                @input="$v.formData.template_id.$touch()"
                @blur="$v.formData.template_id.$touch()"
              />
            </div>
          </template>
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { SET_ACTIONS_DIALOG_SERVER_DATA } from "@/core/services/store/notifications.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      // customer_id: {
      //   required: requiredIf(function () {
      //     return !this.isClient;
      //   }),
      // },
      title: { required },
      receiver_type_id: { required },
      receiver_id: { required },
      action_type_id: { required },
      action_id: { required },
      // user_id: { required },
      // customer_id: { required },
      // customer_account_id: { required },
      day_id: {
        required: requiredIf(function () {
          return this.formData.action_type_id === 1;
        }),
      },
      time: {
        required: requiredIf(function () {
          return this.formData.action_type_id === 1;
        }),
      },
      template_id: {
        required: requiredIf(function () {
          return Boolean(
            this.formData.action_type_id === 2 ||
              this.formData.action_type_id === 3
          );
        }),
      },
    },
  },
  data: () => ({
    dialog: false,
    // timeModal: false,
    formData: {
      title: null,
      receiver_type_id: null,
      receiver_id: null, // type is Array for multiple select and integer for single
      action_type_id: null,
      action_id: null, // type is Array for multiple select and integer for single
      day_id: [1, 2, 3, 4, 5, 6, 7],
      time: "",
      template_id: null,
      // user_id: null,
      // customer_id: null,
      // customer_account_id: null,
    },
    disableWatchers: true,
    userDetails: { label: "Receiver", list: [], isMultiple: true },
    actionNotificationDetails: {
      label: "Action",
      list: [],
      isMultiple: false,
    },
  }),
  methods: {
    loadDataFromServer() {
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/settings/notifications/edit", sendData)
          .then(({ data }) => {
            if (!this.serverData) {
              this.$store.commit(SET_ACTIONS_DIALOG_SERVER_DATA, data);
            }
            this.formData = {
              id: sendData.id,
              ...data.notification,
            };

            // console.log(this.formData.custom_header_value);
            this.dialog = true;
          })
          .catch(() => {
            // console.log("err", err);
            this.dialog = false;
          })
          .finally(() => {
            this.disableWatchers = false;
            this.pageLoader(false);
          });
      } else {
        if (!this.serverData) {
          this.pageLoader(true);
          ApiService.post("/settings/notifications/create")
            .then(({ data }) => {
              this.$store.commit(SET_ACTIONS_DIALOG_SERVER_DATA, data);
              this.pageLoader(false);
            })
            .catch(() => {
              this.dialog = false;
              this.pageLoader(false);
            });
        }
        this.disableWatchers = false;
        this.dialog = true;
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        // this.dialog = !this.dialog;
        this.dialog = false;
      } else {
        this.loadDataFromServer();
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");

        if (fieldName === "time") {
          const reg = RegExp(
            // eslint-disable-next-line
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
          );
          if (!reg.test(this.formData.time)) {
            errors.push("Time have to be in 24h HH:MM format");
          }
          // console.log(this.formData.time);
          // console.log("testing", reg.test(this.formData.time));
        }
      }
      return errors;
    },
    submitForm() {
      // console.log("custom_header_value", this.formData);
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // if (this.$store.getters.isClient) delete this.formData.customer_id;

      const payload = { ...this.formData };

      let actionUrl = "store";
      if (this.actionType) {
        actionUrl = "update";
        payload.id = this.formData.id;
      }
      this.pageLoader(true);
      ApiService.post(`/settings/notifications/${actionUrl}`, payload)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetFormData() {
      this.formData = {
        title: null,
        receiver_type_id: null,
        receiver_id: null,
        action_type_id: null,
        action_id: null,
        day_id: [1, 2, 3, 4, 5, 6, 7],
        time: "",
        template_id: null,
        // user_id: null,
        // customer_id: null,
        // customer_account_id: null,
      };
      // this.timeModal = false;
      this.disableWatchers = true;
      this.userDetails = { label: "Receiver", list: [], isMultiple: true };
      this.actionNotificationDetails = {
        label: "Action",
        list: [],
        isMultiple: false,
      };
    },
  },
  computed: {
    receivers: function () {
      return [
        { index: 1, text: "Users" },
        { index: 2, text: "Customer accounts" },
        { index: 3, text: "End customer" },
      ];
    },
    serverData: function () {
      return this.$store.getters.getNOTIFICATIONSActionsDialogServerData;
    },
    //if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    titleErrors: function () {
      return this.handleFormValidation("title");
    },
    receiver_type_idErrors: function () {
      return this.handleFormValidation("receiver_type_id");
    },
    receiver_idErrors: function () {
      return this.handleFormValidation("receiver_id");
    },
    action_type_idErrors: function () {
      return this.handleFormValidation("action_type_id");
    },
    action_idErrors: function () {
      return this.handleFormValidation("action_id");
    },
    // user_idErrors: function () {
    //   return this.handleFormValidation("user_id");
    // },
    // customer_idErrors: function () {
    //   return this.handleFormValidation("customer_id");
    // },
    // customer_account_idErrors: function () {
    //   return this.handleFormValidation("customer_account_id");
    // },
    day_idErrors: function () {
      return this.handleFormValidation("day_id");
    },
    timeErrors: function () {
      return this.handleFormValidation("time");
    },
    template_idErrors: function () {
      return this.handleFormValidation("template_id");
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    selectedReceiver() {
      return this.formData.receiver_type_id;
    },
    selectedAction() {
      return this.formData.action_type_id;
    },
  },
  watch: {
    selectedReceiver: function (newValue, oldValue) {
      if (newValue && !this.disableWatchers) {
        if (newValue !== oldValue) {
          if (newValue === 1) {
            this.userDetails.label = "Users";
            this.userDetails.list = this.serverData.users;

            this.formData.receiver_id = [];
            this.userDetails.isMultiple = true;
          } else if (newValue === 2) {
            this.userDetails.label = "Customer accounts";
            this.userDetails.list = this.serverData.customer_accounts;

            this.formData.receiver_id = [];
            this.userDetails.isMultiple = true;
          } else if (newValue === 3) {
            this.userDetails.label = "Customers";
            this.userDetails.list = this.serverData.customers;

            this.formData.receiver_id = null;
            this.userDetails.isMultiple = false;
          }
        }
      } else {
        this.userDetails = { label: "Receiver", list: [], isMultiple: true };
      }
    },
    selectedAction: function (newValue, oldValue) {
      if (newValue && !this.disableWatchers) {
        if (newValue !== oldValue) {
          if (newValue === 1) {
            this.formData.template_id = null;

            this.formData.action_id = null;
            this.actionNotificationDetails.isMultiple = false;
          } else {
            this.formData.day_id = [1, 2, 3, 4, 5, 6, 7];
            this.formData.time = "";

            if (newValue === 2) {
              this.formData.action_id = [];
              this.actionNotificationDetails.isMultiple = true;
            } else if (newValue === 3) {
              this.formData.action_id = null;
              this.actionNotificationDetails.isMultiple = false;
            }
          }
          this.actionNotificationDetails.list = this.serverData.values.filter(
            (item) => item.notification_action_id === newValue
          );
          this.actionNotificationDetails.label = this.serverData.actions.find(
            (item) => item.id === newValue
          ).name;
        }
      } else {
        this.actionNotificationDetails = {
          label: "Action",
          list: [],
          isMultiple: false,
        };
      }
    },
  },
};
</script>
